<template>
  <div class="flex flex-col pt-navbar">
    <div class="overflow-y-auto px-container pb-18">
      <!-- BACK -->
      <button
        type="button"
        class="mt-6 flex items-center gap-3 typo-label-sm"
        :aria-label="$t('back')"
        @click="$emit('back')"
      >
        <IconArrowRight class="w-[1.25em] rotate-180" />
        <span class="first-letter:uppercase">{{ $t('back') }}</span>
      </button>

      <!-- SECTION LINK -->
      <Component
        :is="parentPage.url ? 'AppLink' : 'div'"
        v-if="parentPage"
        :key="parentPage.id"
        show-link-arrow
        :to="parentPage.url"
        class="slide-down mt-6 typo-heading-xs"
      >
        {{ parentPage.title }}
      </Component>

      <ul class="mt-6 space-y-8">
        <li
          v-for="subItem in subNavigation"
          :key="subItem.page.id"
          class="slide-down relative border-b will-change-transform"
        >
          <!-- Title -->
          <div
            :class="useIsRouterLinkActive(subItem.page.url, route.path)"
            class="typo-heading-2xs"
          >
            {{ subItem.page.title }}
          </div>

          <!-- Text -->
          <div v-if="subItem.page.nav_text" class="typo-body-xs">
            {{ subItem.page.nav_text }}
          </div>

          <!-- Link -->
          <AppLink
            :aria-label="subItem.page.title"
            class="my-3 before:absolute before:inset-0"
            :to="subItem.page.url"
            show-link-arrow
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  parentPage: { type: Object, required: true },
  subNavigation: { type: Array, required: true },
})

const route = useRoute()

defineEmits(['back'])
</script>
