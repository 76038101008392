import { default as _91_46_46_46uri_93FmSYeNXvC5Meta } from "/vercel/path0/pages/[...uri].vue?macro=true";
export default [
  {
    name: "uri___de",
    path: "/:uri(.*)*",
    meta: _91_46_46_46uri_93FmSYeNXvC5Meta || {},
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___en",
    path: "/en/:uri(.*)*",
    meta: _91_46_46_46uri_93FmSYeNXvC5Meta || {},
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  }
]