import { defineStore } from 'pinia'

export enum NavigationStyle {
  Dark = 'dark',
  Light = 'light',
  Hidden = 'hidden',
}

// Define the store
export const useNavigationStore = defineStore('navigation', () => {
  const navigationStyle = ref<NavigationStyle>(NavigationStyle.Dark)

  return {
    navigationStyle,
  }
})
