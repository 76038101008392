import { defineRule, configure } from 'vee-validate'
import { required, email, numeric } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import de from '@vee-validate/i18n/dist/locale/de.json'
import en from '@vee-validate/i18n/dist/locale/en.json'

export default defineNuxtPlugin((app) => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)

  defineRule('phone', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    const regex = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
    return regex.test(value)
  })

  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: false,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: false,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true,

    generateMessage: localize({
      de,
      en,
    }),
  })

  setLocale(app.$i18n.locale.value)

  // set correct locale on language switched
  app.$i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    setLocale(newLocale)
  }
})
