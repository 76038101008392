<template>
  <svg
    viewBox="0 0 34 23"
    aria-hidden="true"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 20 0 L 32 11 L 20 22 M 32 11  M 0 11 H 32" />
  </svg>
</template>
