<template>
  <nav
    class="fixed inset-x-0 top-0 z-40 px-container-md transition-all duration-500 ease-in-out"
    :class="
      isScrolling ? 'h-24 bg-beige-500' : 'h-navbar-desktop bg-transparent'
    "
  >
    <ul class="flex size-full items-start py-4">
      <!-- HOME -->
      <li key="home" class="relative mr-auto h-full w-[16.5rem]">
        <AppLink
          class="absolute left-0 top-0 w-full"
          :to="localePath('/')"
          :aria-label="$t('homepage')"
        >
          <IconBaryonBanner
            is-small
            :animate="isScrolling"
            class="w-full transition-colors"
            :class="{
              'text-beige-500':
                navigationStyle === NavigationStyle.Light && !isScrolling,
            }"
          />
        </AppLink>
      </li>

      <!-- NAVIGATION -->
      <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
      <li
        v-for="(item, index) in navigationMain"
        :id="`sub-nav-item-${index}`"
        :key="item.page.id"
        :ref="`nav-item-${index}`"
        class="router-link px-4 transition-[margin-top] duration-500 ease-in-out"
        :class="isScrolling ? 'mt-5' : 'mt-6'"
        @mouseenter="enter(index, item.children?.length)"
        @mouseleave="leave()"
      >
        <div class="relative">
          <Component
            :is="item.page.url ? 'AppLink' : 'div'"
            v-bind="item.page?.url ? { to: item.page.url } : {}"
            :id="
              item.children?.length > 0
                ? `dropdownButton-${index}`
                : `nav-link-${index}`
            "
            :aria-haspopup="item.children?.length > 0"
            :aria-expanded="index === indexToShow"
            class="relative z-10 -mt-5 pt-5 typo-label-md"
            :class="[
              useIsRouterLinkActive(item.page.url, route.path),
              {
                '!text-beige-500 hover:!text-beige-600 group-hover:!text-beige-600':
                  navigationStyle === NavigationStyle.Light && !isScrolling,
              },
            ]"
            @focus="enter(index, item.children?.length)"
          >
            {{ item.page.title }}
          </Component>

          <!-- Navigation Hover Area -->
          <span
            v-if="index === indexToShow"
            class="nav-hover-area absolute bottom-3 left-0 h-26 w-full translate-y-full scale-x-[6]"
          />
        </div>

        <!-- SUB NAVIGATION -->
        <NavigationMainSub
          v-show="index === indexToShow"
          :id="`sub-nav-${index}`"
          :key="`sub-nav-${indexToShow}`"
          :parent-page="item.page"
          :sub-navigation="item.children"
          :aria-labelledby="`dropdownButton-${index}`"
          :aria-hidden="index !== indexToShow"
          class="transition-[top] duration-500 ease-in-out"
          :class="isScrolling ? 'top-24' : 'top-navbar-desktop'"
        />
      </li>

      <!-- LANG SWITCHER -->

      <li
        class="pl-4"
        :class="{
          'text-beige-500':
            navigationStyle === NavigationStyle.Light && !isScrolling,
        }"
      >
        <LangSwitcher
          class="transition-[margin-top] duration-500 ease-in-out"
          :class="[isScrolling ? 'mt-5' : 'mt-6']"
        />
      </li>
    </ul>

    <!-- Navigation background to hide jumping -->
    <div
      class="pointer-events-none absolute inset-x-container-md z-40 bg-gradient-beige-to-gray transition-[top,height] duration-500 ease-in-out"
      :class="isScrolling ? 'top-28' : 'top-navbar-desktop'"
      :style="{
        height: subNavHeight + 'px',
      }"
    />
  </nav>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useNavigationStore, NavigationStyle } from '~/stores/navigation'

const { navigationStyle } = storeToRefs(useNavigationStore())

defineProps({
  isScrolling: { type: Boolean, default: false },
})

const localePath = useLocalePath()
const globals = inject(GlobalsKey)
const route = useRoute()

// Determines if a sub navigation should be shown and which one
const indexToShow = ref(-1)

// Sub navigation height used by the background
const subNavHeight = ref(0)

const navigationMain = computed(() => {
  return globals?.value?.main?.tree || []
})

async function enter(index, hasSubNav) {
  // If the hovered item has no sub navigation, leave
  if (!hasSubNav) {
    leave()
    return
  }

  // This opens the sub navigation
  indexToShow.value = index

  // Wait one tick until the sub navigation is rendered
  await nextTick()

  // Get the height of the sub navigation to set the height of the background
  const subNav = document.getElementById(`sub-nav-${index}`)
  subNavHeight.value = subNav.offsetHeight

  // Wait until the height is set to show the sub navigation
  await new Promise((resolve) => setTimeout(resolve, 200))
}

function leave() {
  // -1 means no sub navigation should be shown
  indexToShow.value = -1
  subNavHeight.value = 0
}

watch(
  () => route.path,
  () => {
    leave()
  }
)
</script>

<style scoped lang="scss">
.nav-hover-area {
  clip-path: polygon(40% 0%, 60% 0%, 100% 100%, 0% 100%);
}
</style>
