<template>
  <nav
    class="pointer-events-auto fixed inset-x-0 top-0 z-40 transition-colors duration-500"
    :class="isScrolling ? 'bg-beige-500' : ' bg-transparent'"
  >
    <!-- HEADER -->
    <div
      class="relative z-50 flex h-navbar items-center justify-between px-container py-3.5"
    >
      <!-- Home -->
      <AppLink :to="localePath('/')" :aria-label="$t('homepage')">
        <IconBaryonBanner
          :animate="isMobileNavOpen || isScrolling"
          class="w-[8.5rem]"
        />
      </AppLink>

      <!-- Burger Button -->
      <button
        class="relative h-5 w-16 shrink-0 outline-none focus-visible:ring"
        type="button"
        :aria-label="$t('open_nav')"
        @click="isMobileNavOpen = !isMobileNavOpen"
      >
        <div
          :class="isMobileNavOpen ? 'top-1/2 -rotate-45 scale-x-50' : 'top-0'"
          class="absolute w-full origin-center -translate-y-1/2 border transition-all delay-75 duration-500 ease-in-out"
        />
        <div
          :class="{ 'scale-x-0': isMobileNavOpen }"
          class="absolute top-1/2 w-full origin-center -translate-y-1/2 border transition-transform duration-500 ease-in-out"
        />
        <div
          :class="
            isMobileNavOpen ? 'bottom-1/2 rotate-45 scale-x-50' : 'bottom-0'
          "
          class="absolute w-full origin-center translate-y-1/2 border transition-all delay-75 duration-500 ease-in-out"
        />
      </button>
    </div>

    <!-- NAVIGATION -->

    <Transition @enter="enterAnimation" @leave="leaveAnimation">
      <div
        v-show="isMobileNavOpen"
        ref="collapseElement"
        class="fixed top-0 z-40 w-[200vw] bg-gradient-beige-to-gray transition-transform duration-500"
        :class="translation"
      >
        <div class="pt-navbar shadow-xl">
          <div
            class="flex h-[calc(100svh-5.375rem)] flex-col justify-between overflow-y-auto pb-9"
          >
            <ul class="router-link pb-18">
              <!-- MAIN LEVEL 1 -->
              <li class="slide-left my-5 px-container">
                <AppLink class="typo-heading-xs" to="/"> Startseite </AppLink>
              </li>

              <li
                v-for="item in navigationMain"
                :key="item.page.id"
                class="mb-5 w-1/2"
              >
                <div
                  class="slide-left flex items-center gap-3 px-container will-change-transform"
                >
                  <!-- Link -->
                  <Component
                    :is="item.page.url ? 'AppLink' : 'div'"
                    v-if="!item.children?.length"
                    class="text-left typo-heading-xs"
                    :to="item.page.url"
                    :class="useIsRouterLinkActive(item.page.url, route.path)"
                  >
                    {{ item.page.title }}
                  </Component>

                  <!-- Go to children -->
                  <AppLink
                    v-else
                    class="cursor-pointer gap-3 text-left typo-heading-xs"
                    :aria-label="`Zeige die Unternavigationselemente von ${item.page.title} an`"
                    :class="useIsRouterLinkActive(item.page.url, route.path)"
                    show-link-arrow
                    @click="activeParentPage = item"
                  >
                    {{ item.page.title }}
                  </AppLink>
                </div>

                <!-- SUB NAVIGATION -->
                <template v-if="item.children.length">
                  <Transition
                    @enter="enterAnimation"
                    @leave="leaveAnimationSub"
                  >
                    <NavigationMobileSub
                      v-show="activeParentPage === item"
                      :parent-page="item.page"
                      :sub-navigation="item.children"
                      class="absolute inset-y-0 left-1/2 right-0"
                      @back="activeParentPage = null"
                    />
                  </Transition>
                </template>
              </li>
            </ul>

            <!-- LANG SWITCHER -->
            <LangSwitcher variant="buttons" class="px-container" />
          </div>
        </div>
      </div>
    </Transition>
  </nav>
</template>

<script setup lang="ts">
import { useScrollLock } from '@vueuse/core'

defineProps({
  isScrolling: { type: Boolean, default: false },
})

const globals = inject(GlobalsKey)
const route = useRoute()
const localePath = useLocalePath()

const collapseElement = ref()
const options = {
  duration: 500,
  initiallyClosed: true,
}
const isMobileNavOpen = useCollapse(collapseElement, options)

isMobileNavOpen.value = false

const activeParentPage = ref(null)

const translation = ref('translate-0')

const body = ref(null)
let isLocked

const navigationMain = computed(() => {
  return globals?.value?.main?.tree || []
})

function enterAnimation(el, done) {
  let slideDownElements
  if (!activeParentPage.value) {
    slideDownElements = el.querySelectorAll('.slide-left')
  } else {
    slideDownElements = el.querySelectorAll('.slide-down')
  }

  if (slideDownElements.length > 0) {
    const tl = useGsap.timeline({ onComplete: done }).delay(0.8) // Add a delay of 0.5 seconds

    slideDownElements.forEach((child) => {
      if (!activeParentPage.value) {
        tl.fromTo(
          child,
          { x: -20, opacity: 0 },
          { x: 0, opacity: 1, duration: 0.5 },
          '-=0.4' // Overlap the animations slightly
        )
      } else {
        tl.fromTo(
          child,
          { y: -20, opacity: 0 },
          { y: 0, opacity: 1, duration: 0.5 },
          '-=0.4' // Overlap the animations slightly
        )
      }
    })
  } else {
    done() // Call done immediately if no slide-down elements
  }
}

function leaveAnimation(el, done) {
  let slideDownElements
  if (!activeParentPage.value) {
    slideDownElements = Array.from(el.querySelectorAll('.slide-left')).reverse()
  } else {
    slideDownElements = Array.from(el.querySelectorAll('.slide-down')).reverse()
  }

  if (slideDownElements.length > 0) {
    const tl = useGsap.timeline({ onComplete: done })

    if (!activeParentPage.value) {
      slideDownElements.forEach((child) => {
        tl.to(child, { x: -20, opacity: 0, duration: 0.5 }, '-=0.4') // Apply animation
      })
    } else {
      slideDownElements.forEach((child) => {
        tl.to(child, { y: -20, opacity: 0, duration: 0.5 }, '-=0.4') // Apply animation
      })
    }
  } else {
    done() // Call done immediately if no slide-down elements
  }
}

function leaveAnimationSub(el, done) {
  const slideDownElements = Array.from(
    el.querySelectorAll('.slide-down')
  ).reverse() // Reverse the order of elements
  if (slideDownElements.length > 0) {
    const tl = useGsap.timeline({ onComplete: done })

    slideDownElements.forEach((child) => {
      tl.to(child, { y: -20, opacity: 0, duration: 0.5 }, '-=0.4') // Apply animation
    })
  } else {
    done() // Call done immediately if no slide-down elements
  }
}

watch(
  () => route.path,
  () => {
    isMobileNavOpen.value = false
  }
)

watch(
  () => activeParentPage.value,
  (newVal) => {
    if (newVal) {
      translation.value = '-translate-x-1/2'
      return
    }
    return (translation.value = 'translate-0 delay-300')
  }
)

watch(
  () => isMobileNavOpen.value,
  (newVal) => {
    isLocked.value = newVal
  }
)

onMounted(() => {
  // Since document.body is available only client-side, assign it here
  body.value = document.body
  isLocked = useScrollLock(body)
})

onUnmounted(() => {
  // Ensure the body is unlocked when the component is unmounted
  if (isLocked.value) {
    isLocked.value = false
  }
})
</script>

<style scoped lang="scss">
.box-shadow-top {
  box-shadow: 0px -1px 10px 0px #22242b;
}
.width-enter-active,
.width-leave-active {
  transition-property: opacity, width;
  @apply duration-300 ease-out;
}

.width-enter-from,
.width-leave-to {
  @apply w-0 opacity-0;
}

.left-in-enter-active,
.left-in-leave-active {
  transition-property: opacity, transform;
  @apply duration-300 ease-out;
}

.left-in-enter-from,
.left-in-leave-to {
  @apply -translate-x-full opacity-0;
}
</style>
