<template>
  <!-- DESKTOP -->
  <NavigationMain
    v-show="navigationStyle !== NavigationStyle.Hidden"
    :is-scrolling="isScrolling"
    class="max-xl:hidden"
  />

  <!-- MOBILE -->
  <NavigationMobile
    v-show="navigationStyle !== NavigationStyle.Hidden"
    :is-scrolling="isScrolling"
    class="xl:hidden"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useNavigationStore, NavigationStyle } from '~/stores/navigation'

const { navigationStyle } = storeToRefs(useNavigationStore())

const y = ref(0)

const isScrolling = computed(() => {
  return y.value > 0
})

onMounted(() => {
  y.value = window.scrollY
  window.addEventListener('scroll', () => {
    y.value = window.scrollY
  })
})

onUnmounted(() => {
  window.removeEventListener('scroll', () => {
    y.value = window.scrollY
  })
})
</script>
