// Since we have most of the pages dynamic rendered, it seems that the router-link-active class can not be set from router directly.

export const useIsRouterLinkActive = (url: string, path: string) => {
  if (!url || !path) return ''

  // Check if the current route's path starts with the provided URL
  // Note: It's important to ensure both URLs are normalized (trailing slashes are handled consistently)
  const normalizedRoutePath = path.endsWith('/') ? path : `${path}/`
  const normalizedUrl = url.endsWith('/') ? url : `${url}/`

  // Check for an exact match. In this case the classes gets applied correct through the router
  if (normalizedRoutePath === normalizedUrl) {
    return ''
  }

  return normalizedRoutePath.startsWith(normalizedUrl)
    ? 'router-link-active'
    : ''
}
