<template>
  <div ref="el" class="animated-container">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  start: { type: String, default: null },
  end: { type: String, default: null },
  markers: { type: Boolean, default: false },
  delay: { type: Number, default: 0 },
  stagger: { type: Number, default: 0.1 },
})

const el = ref(null)
let ctx: any = null

onMounted(() => {
  useGsap.delayedCall(props.delay, () => {
    initAnimation()
  })
})

onUnmounted(() => {
  ctx?.revert()
})

const initAnimation = () => {
  ctx = useGsap.context((self) => {
    if (!self || !self.selector) return
    const slideInUp = self.selector('.slide-up')
    const slideInDown = self.selector('.slide-down')
    const slideInLeft = self.selector('.slide-left')
    const slideInRight = self.selector('.slide-right')
    const zoomIn = self.selector('.zoom-in')
    const zoomInOut = self.selector('.zoom-in-out')
    const fadeIn = self.selector('.fade-in')

    const tl = useGsap.timeline({
      scrollTrigger: {
        trigger: el.value,
        start: props.start || 'top 85%',
        end: props.end || 'bottom top',
        markers: props.markers || false,
      },
    })

    if (slideInUp.length > 0)
      tl.add(
        useGsap.to(slideInUp, {
          autoAlpha: 1,
          y: 0,
          stagger: props.stagger,
        })
      )
    if (slideInDown.length > 0)
      tl.add(
        useGsap.to(slideInDown, {
          autoAlpha: 1,
          y: 0,
          stagger: props.stagger,
        })
      )
    if (slideInLeft.length > 0)
      tl.add(
        useGsap.to(slideInLeft, {
          autoAlpha: 1,
          x: 0,
          stagger: props.stagger,
        })
      )
    if (slideInRight.length > 0)
      tl.add(
        useGsap.to(slideInRight, {
          autoAlpha: 1,
          x: 0,
          stagger: props.stagger,
        })
      )
    if (zoomIn.length > 0) {
      tl.add(
        useGsap.to(zoomIn, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.4,
          stagger: 0.15,
        }),
        0
      )
    }

    if (zoomInOut.length > 0) {
      tl.add(
        useGsap.to(zoomInOut, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.9,
          stagger: 0.15,
          ease: 'back.out(1.2)',
        }),
        0
      )
    }

    if (fadeIn.length > 0) {
      tl.add(
        useGsap.to(fadeIn, {
          autoAlpha: 1,
          duration: 0.4,
          stagger: 0.15,
        })
      )
    }
  }, el.value)
}
</script>

<style>
body:not(.live-preview-active) {
  .animated-container {
    .slide-up {
      @apply invisible;
      transform-box: fill-box;
      transform: translateY(2.75rem);
    }

    .slide-down {
      @apply invisible;
      transform-box: fill-box;
      transform: translateY(-2.75rem);
    }

    .slide-left {
      @apply invisible;
      transform-box: fill-box;
      transform: translatex(-2.75rem);
    }

    .slide-right {
      @apply invisible;
      transform-box: fill-box;
      transform: translatex(2.75rem);
    }

    .fade-in {
      @apply invisible;
    }

    .zoom-in {
      @apply invisible;
      transform-box: fill-box;
      transform: scale(0.9);
      transform-origin: center;
    }

    .zoom-in-out {
      @apply invisible;
      transform-box: fill-box;
      transform: scale(0);
      transform-origin: center;
    }

    .scroll-to-top {
      transform-box: fill-box;
      transform: translateY(5rem);
    }
  }
}
</style>
