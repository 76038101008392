<template>
  <footer class="px-container py-20 sm:px-container-md sm:pt-36">
    <div
      class="flex flex-col justify-between gap-10 border-t border-gray-500 py-20 sm:flex-wrap lg:flex-row xl:flex-nowrap"
    >
      <!-- HOME -->
      <AppLink class="h-fit" :to="localePath('/')" :aria-label="$t('homepage')">
        <IconBaryonBanner class="w-44 sm:w-64" />
      </AppLink>

      <!-- ADDRESS -->
      <div v-if="globals" class="relative">
        <address
          class="footer-address whitespace-nowrap not-italic typo-body-xs sm:typo-body-sm"
          v-html="globals?.general?.address"
        />
        <a
          v-if="globals?.general?.link_maps"
          :href="globals?.general?.link_maps"
          target="_blank"
          class="before:absolute before:inset-0"
          aria-label="Open Google Maps"
        />
      </div>

      <div class="lg:mt-9">
        <!-- PHONE -->
        <AppLink
          v-if="globals?.general?.phone"
          :to="`tel:${globals?.general?.phone}`"
          class="block typo-body-xs sm:typo-body-sm"
        >
          {{ $t('phone_short') }} {{ globals?.general?.phone }}
        </AppLink>

        <!-- EMAIL -->
        <AppLink
          v-if="globals?.general?.email"
          :to="`mailto:${globals?.general?.email}`"
          class="block typo-body-xs sm:typo-body-sm"
        >
          {{ globals?.general?.email }}
        </AppLink>
      </div>

      <!-- LINK -->
      <div class="group relative h-fit border-b border-gray-500 max-xl:w-full">
        <AppLink
          v-if="
            globals?.footer_globals?.link && globals?.footer_globals?.link_text
          "
          class="typo-heading-2xs after:absolute after:inset-0 sm:typo-heading-xs"
          :to="globals?.footer_globals?.link"
        >
          {{ globals?.footer_globals?.link_text }}
        </AppLink>

        <IconArrowRight
          class="mb-4 mt-3 w-7 transition-transform duration-300 ease-out group-hover:translate-x-3"
        />
      </div>
    </div>

    <div
      class="flex flex-col flex-wrap justify-between gap-x-12 gap-y-6 md:flex-row"
    >
      <!-- COPYRIGHT -->
      <p
        v-if="globals?.footer_globals?.text_copyright"
        class="hyphens-none typo-heading-2xs"
      >
        © {{ globals?.footer_globals?.text_copyright }}
      </p>
      <ul class="flex flex-wrap space-x-8">
        <li v-for="{ page } in globals?.footer.tree" :key="page.id">
          <AppLink class="typo-label-sm" :to="page.url">{{
            page.title
          }}</AppLink>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
const globals = inject(GlobalsKey)
</script>

<style>
.footer-address {
  p:has(strong) {
    @apply pb-1 sm:pb-[0.375rem];
  }
  strong {
    @apply typo-heading-2xs sm:typo-heading-xs;
  }
}
</style>
