<template>
  <svg
    width="16"
    height="17"
    aria-hidden="true"
    fill="none"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12 6.457-4 4.086-4-4.086"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
