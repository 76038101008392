<template>
  <svg
    viewBox="0 0 42 42"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    stroke-width="1.5"
  >
    <path d="M41 41L0.999996 1.00002" stroke="currentColor" />
    <path d="M41 1L1 41" stroke="currentColor" />
  </svg>
</template>
