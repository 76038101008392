<template>
  <NuxtLoadingIndicator :color="false" />
  <div class="bg-beige-500 text-gray-500">
    <div class="flex min-h-screen w-full flex-col justify-between">
      <Navigation />

      <main class="relative flex flex-1 flex-col">
        <!-- the bg-beige-500 class is necessary for the page overlap transition -->
        <NuxtPage class="bg-beige-500" />
      </main>
    </div>
    <AppFooter />

    <!-- COOKIE BANNER -->
    <CookieBanner />
  </div>
</template>

<script lang="ts" setup>
const formatError = (error: unknown) => {
  return createError({
    fatal: true,
    // @ts-ignore
    statusCode: error?.status ?? 500,
    message: error instanceof Error ? error.message : 'An error occurred',
  })
}

const [globals, { entry, refresh }] = await Promise.all([
  useInjectableGlobals(),
  useInjectableEntry(),
]).catch((error) => {
  throw formatError(error)
})

provide(GlobalsKey, globals)
provide(EntryInjectionKey, entry)

const router = useRouter()
router.beforeResolve(async (route) => {
  await refresh(route).catch((error) => {
    // trick vou-router into complete the navigation
    // in order to update the url and create stack entry
    setTimeout(() => showError(formatError(error)), 0)
  })
})

if (process.client) {
  document.body.classList.add(useIsTouchDevice() ? 'is-touch' : 'no-touch')
}
</script>
