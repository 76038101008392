import revive_payload_client_TWsSWmJIDP from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tlIem0AILG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BGxI4eAHPj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_00ZOMsO0Ob from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TjjZj0q6NN from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VY8KytSj3r from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1uHePxdIjo from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OgIGSd8tJk from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.31_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_KObV1BFqfl from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_iswxmi654fzadifz64s6vrteee/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JQfFSnLhaj from "/vercel/path0/node_modules/.pnpm/nuxt-echarts@0.2.2_echarts@5.5.1_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_BIROhJ0uMJ from "/vercel/path0/node_modules/.pnpm/nuxt-graphql-client@0.2.35_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_graphql_6wdbevvfhyzqbaykccisx3zdsi/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import animations_QfOCKY5zQw from "/vercel/path0/node_modules/.pnpm/@teamnovu+kit-nuxt@1.1.4_magicast@0.3.4_nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.1_zlptyff5oz2yvsn2t4cgy7pyyi/node_modules/@teamnovu/kit-nuxt/src/runtime/animations.ts";
import i18n_jaB8o6MZYI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import inlineUtils_X58FG1MpoS from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/inlineUtils.ts";
import livePreview_RrjgcDm40s from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/livePreview.ts";
import onError_lHlIdZiLD7 from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/onError.ts";
import vue_cloudinary_image_GGoxRgqUcV from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/vue-cloudinary-image.ts";
import inlineUtils_mS4ZLogVwO from "/vercel/path0/plugins/inlineUtils.ts";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vee_validate_K3WwmJMPDb from "/vercel/path0/plugins/vee-validate.js";
export default [
  revive_payload_client_TWsSWmJIDP,
  unhead_tlIem0AILG,
  router_BGxI4eAHPj,
  payload_client_00ZOMsO0Ob,
  navigation_repaint_client_TjjZj0q6NN,
  check_outdated_build_client_VY8KytSj3r,
  chunk_reload_client_1uHePxdIjo,
  plugin_vue3_OgIGSd8tJk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KObV1BFqfl,
  plugin_JQfFSnLhaj,
  plugin_BIROhJ0uMJ,
  animations_QfOCKY5zQw,
  i18n_jaB8o6MZYI,
  inlineUtils_X58FG1MpoS,
  livePreview_RrjgcDm40s,
  onError_lHlIdZiLD7,
  vue_cloudinary_image_GGoxRgqUcV,
  inlineUtils_mS4ZLogVwO,
  sentry_client_GoGQuZo4Et,
  vee_validate_K3WwmJMPDb
]