<template>
  <div
    class="flex min-h-screen w-full flex-col justify-between pt-navbar-height"
  >
    <NuxtErrorBoundary>
      <Navigation />
    </NuxtErrorBoundary>

    <NuxtErrorBoundary>
      <main class="flex flex-1 flex-col justify-center">
        <h1 class="mx-auto text-center text-4xl font-bold">
          {{ error.statusCode }}
        </h1>
        <h2 class="mx-auto my-4 max-w-xl text-center">
          {{ props.error.statusCode ? $t('error.404') : $t('error.500') }}
        </h2>

        <!-- LINK -->
        <NuxtLink
          to="/"
          class="group mx-auto w-max text-sm font-bold hover:underline"
        >
          <I18nT tag="span" keypath="error.backToHomepage" />
        </NuxtLink>

        
      </main>
    </NuxtErrorBoundary>

    <NuxtErrorBoundary>
      <AppFooter />
    </NuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
if (process.client) {
  document.body.classList.add(useIsTouchDevice() ? 'is-touch' : 'no-touch')
}

const props = defineProps<{
  error: any
}>()

try {
  provide(GlobalsKey, await useInjectableGlobals())
} catch (error) {
  console.error('[ERROR Page]: Could not load globals', error)
}

try {
  const { refresh } = await useInjectableEntry()
  const router = useRouter()
  router.beforeResolve(refresh)
} catch (error) {
  console.error('[ERROR Page]: Could not load entry', error)
}
</script>
