<template>
  <div v-if="alternates.length > 1">
    <div v-if="variant === 'select'">
      <SingleSelect
        :model-value="{
          id: currentLocale,
          slug: currentLocale,
          title: currentLocale,
        }"
        :options="alternateLocales"
        @update:model-value="switchLanguage($event)"
      />
    </div>

    <div v-else-if="variant === 'buttons'" class="flex flex-wrap gap-6">
      <button
        v-for="{ locale, url } in alternates"
        :key="url"
        type="button"
        class="uppercase"
        :class="{
          'underline decoration-[0.09375em] underline-offset-[0.375em]':
            currentLocale === locale,
        }"
        @click="switchLanguage"
      >
        {{ locale }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  variant: { type: String, default: 'select' }, // select or buttons
})

const switchLocalePath = useSwitchLocalePath()
const { locale: currentLocale } = useI18n()
const entry = inject(EntryInjectionKey)

// set page alternate links
const alternates = computed(() => {
  if (Array.isArray(entry?.value?.alternates)) {
    return entry.value.alternates
  } else {
    return []
  }
})

const alternateLocales = computed(() => {
  return alternates.value.map((a) => {
    return {
      id: a.locale,
      slug: a.locale,
      title: a.locale,
    }
  })
})

const switchLanguage = (event) => {
  const selectedLocale = event.id

  if (Array.isArray(alternates.value) && alternates.value.length) {
    const { url } = alternates.value.find((a) => a.locale === selectedLocale)
    return navigateTo(url)
  }

  navigateTo(switchLocalePath(selectedLocale))
}
</script>
