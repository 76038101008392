import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlueprintNewsArticle, LazyBlueprintPage, LazyBlueprintTeamMember, LazyHeroDefault, LazyHeroHome, LazyHeroSection, LazyHeroTeamMember, LazyAccordionWithImage, LazyChart, LazyCustomText, LazyDownloads, LazyImageWithText, LazyImages, LazyLeadText, LazyNewsletterRegistration, LazyOverviewNews, LazyOverviewTeam, LazySliderTeam, LazyTeaserImage, LazyTeaserPageLinks, LazyTeaserSlider, LazyIconArrowLeft, LazyIconArrowRight, LazyIconBaryonBanner, LazyIconBaryonBannerWhite, LazyIconBaryonLogo, LazyIconBaryonLogoWhite, LazyIconChevron, LazyIconCross, LazyIconDownload, LazyIconMagnifyingGlass, LazyIconMail, LazyIconScrollCTA, LazyAnimatedContainer, LazyAnimatedContainerScroll, LazyAppImage, LazyAppLink, LazyBreaky, LazyCardNavigationSub, LazyCardNewsArticle, LazyCardTeamMember, LazyContainer, LazyCookieBanner, LazyDownload, LazyGlobals, LazyGradientOverlay, LazyLoaderComponents, LazyLoaderHeros, LazyPagination, LazyParallaxElement, LazyScrolly, LazyTextAppear } from '#components'
const lazyGlobalComponents = [
  ["BlueprintNewsArticle", LazyBlueprintNewsArticle],
["BlueprintPage", LazyBlueprintPage],
["BlueprintTeamMember", LazyBlueprintTeamMember],
["HeroDefault", LazyHeroDefault],
["HeroHome", LazyHeroHome],
["HeroSection", LazyHeroSection],
["HeroTeamMember", LazyHeroTeamMember],
["AccordionWithImage", LazyAccordionWithImage],
["Chart", LazyChart],
["CustomText", LazyCustomText],
["Downloads", LazyDownloads],
["ImageWithText", LazyImageWithText],
["Images", LazyImages],
["LeadText", LazyLeadText],
["NewsletterRegistration", LazyNewsletterRegistration],
["OverviewNews", LazyOverviewNews],
["OverviewTeam", LazyOverviewTeam],
["SliderTeam", LazySliderTeam],
["TeaserImage", LazyTeaserImage],
["TeaserPageLinks", LazyTeaserPageLinks],
["TeaserSlider", LazyTeaserSlider],
["IconArrowLeft", LazyIconArrowLeft],
["IconArrowRight", LazyIconArrowRight],
["IconBaryonBanner", LazyIconBaryonBanner],
["IconBaryonBannerWhite", LazyIconBaryonBannerWhite],
["IconBaryonLogo", LazyIconBaryonLogo],
["IconBaryonLogoWhite", LazyIconBaryonLogoWhite],
["IconChevron", LazyIconChevron],
["IconCross", LazyIconCross],
["IconDownload", LazyIconDownload],
["IconMagnifyingGlass", LazyIconMagnifyingGlass],
["IconMail", LazyIconMail],
["IconScrollCTA", LazyIconScrollCTA],
["AnimatedContainer", LazyAnimatedContainer],
["AnimatedContainerScroll", LazyAnimatedContainerScroll],
["AppImage", LazyAppImage],
["AppLink", LazyAppLink],
["Breaky", LazyBreaky],
["CardNavigationSub", LazyCardNavigationSub],
["CardNewsArticle", LazyCardNewsArticle],
["CardTeamMember", LazyCardTeamMember],
["Container", LazyContainer],
["CookieBanner", LazyCookieBanner],
["Download", LazyDownload],
["Globals", LazyGlobals],
["GradientOverlay", LazyGradientOverlay],
["LoaderComponents", LazyLoaderComponents],
["LoaderHeros", LazyLoaderHeros],
["Pagination", LazyPagination],
["ParallaxElement", LazyParallaxElement],
["Scrolly", LazyScrolly],
["TextAppear", LazyTextAppear],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
