<template>
  <Component
    :is="buttonOrNuxtLink"
    v-bind="
      to
        ? {
            to: to,
            target: target || $useIsExternalUrl(to) ? '_blank' : '_self',
          }
        : {}
    "
    :class="{ 'gap-[0.5em]': showLinkArrow }"
    class="group flex items-center text-base font-medium text-gray-500 transition-colors hover:text-gray-300 group-hover:text-gray-300"
  >
    <IconArrowLeft
      v-if="arrowLeft && showLinkArrow"
      class="h-[1em] min-w-3 transition-transform group-hover:translate-x-[-0.5em]"
    />

    <slot />

    <IconArrowRight
      v-if="!arrowLeft && showLinkArrow"
      class="h-[1em] min-w-3 transition-transform group-hover:translate-x-[0.5em]"
      :class="{ 'rotate-45': $useIsExternalUrl(to) }"
    />
  </Component>
</template>

<script setup lang="ts">
const props = defineProps({
  to: { type: [String, Object], default: null },
  showLinkArrow: { type: Boolean, default: false },
  arrowLeft: { type: Boolean, default: false },
  target: { type: String, default: null },
})

// determine whether to load NuxtLink or button
const buttonOrNuxtLink = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }

  return 'button'
})
</script>
