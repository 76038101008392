<template>
  <div
    ref="element"
    :class="
      hover ? 'text-gray-300 sm:mt-0' : 'delay-300 sm:translate-y-[1.5rem]'
    "
    class="element relative text-left transition-[transform,color] duration-300 ease-in-out sm:h-56"
  >
    <!-- TITLE -->
    <AppLink
      :to="entry.url"
      class="mb-2 max-w-[30ch] typo-heading-xs before:absolute before:inset-0 before:z-40"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @focus="onMouseEnter"
      @blur="onMouseLeave"
    >
      <span class="line-clamp-3">{{ entry.title }}</span>
    </AppLink>

    <!-- TEXT -->
    <span
      v-if="entry.nav_text"
      class="slide-left line-clamp-5 max-w-title !no-underline typo-body-xs"
    >
      {{ entry.nav_text }}
    </span>

    <!-- ARROW -->
    <div class="icon-arrow">
      <IconArrowRight class="my-2 size-6" />
    </div>

    <!-- LINE -->
    <div class="line h-px w-full origin-left bg-gray-300" />
  </div>
</template>

<script setup lang="ts">
defineProps<{
  entry: {
    id: string
    title: string
    url: string
    nav_text: string
  }
}>()

const hover = ref(false)
const element = ref(null)
const hoverTL = ref(null)

const initHoverAnimation = () => {
  // https://gsap.com/docs/v3/Plugins/ScrollTrigger/static.isTouch/
  const { isTouch } = useScrollTrigger

  if (isTouch === 1) {
    return
  }

  useGsap.context(() => {
    hoverTL.value = useGsap
      .timeline({ paused: true })
      .to('.icon-arrow', {
        x: 0,
        opacity: 1,
        duration: 0.2,
        delay: 0.15,
      })
      .to('.line', { scaleX: 1, duration: 0.4 }, 0)
  }, element.value)
}

function onMouseEnter() {
  hover.value = true

  hoverTL.value?.play()
}

function onMouseLeave() {
  hover.value = false

  hoverTL.value?.reverse()
}

onMounted(() => {
  initHoverAnimation()
})
</script>

<style scoped>
.icon-arrow {
  @apply -translate-x-3 opacity-0;
}
.line {
  @apply scale-x-0;
}
/* Add any necessary styles here */
</style>
