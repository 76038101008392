<template>
  <svg
    viewBox="0 0 34 23"
    aria-hidden="true"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 12 22 L 0 11 L 12 0 M 0 11 H 32" />
  </svg>
</template>
