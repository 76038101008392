<template>
  <Listbox
    v-slot="{ open }"
    as="div"
    by="slug"
    class="relative"
    :model-value="modelValue"
    @update:model-value="(value) => emit('update:modelValue', value)"
  >
    <!-- HEADER -->
    <ListboxButton
      :id="`headlessui-listbox-button-${title}`"
      v-slot="{ value }"
    >
      <div
        class="flex w-full items-center justify-between pb-1 pl-1 transition-colors duration-200"
      >
        <span class="uppercase typo-label-md">
          {{ value.title }}
        </span>
        <IconChevron
          class="mb-px size-5 shrink-0 transition-transform"
          :class="open ? 'rotate-180' : 'rotate-0'"
        />
      </div>
    </ListboxButton>

    <!-- OPTIONS -->
    <Transition :open="open" name="fade">
      <ListboxOptions class="absolute top-full z-20 bg-beige-300 shadow-sm">
        <ListboxOption
          v-for="option in options"
          :key="option.slug"
          :value="option"
          class="cursor-pointer px-4 py-3 uppercase text-gray-500 transition-colors typo-label-sm hover:bg-beige-400"
        >
          {{ option.title }}
        </ListboxOption>
      </ListboxOptions>
    </Transition>
  </Listbox>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'

defineProps({
  title: { type: String, default: '' },
  placeholder: { type: String, default: 'jetzt wählen' },
  options: { type: Array, default: () => [] },
  modelValue: { type: Object, default: () => ({}) },
})

const emit = defineEmits(['update:modelValue'])
</script>
