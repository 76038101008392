<template>
  <AnimatedContainer
    v-if="showCookieBanner && globals?.cookie_banner"
    class="hide-on-live-preview pointer-events-none fixed inset-x-0 bottom-9 z-50 flex justify-end px-container sm:px-container-md"
  >
    <div
      class="gradient slide-up pointer-events-auto max-w-xl p-10 text-beige-900"
    >
      <!-- TEXT -->
      <div
        v-if="globals?.cookie_banner?.text"
        class="default-text-styles mb-8 typo-body-sm max-xs:hyphens-auto"
        v-html="globals.cookie_banner.text"
      />

      <!-- BUTTON -->
      <button
        type="button"
        class="flex items-center gap-2 typo-button-md hover:text-gray-300"
        @click="closeCookieBanner()"
      >
        <span>{{ globals.cookie_banner.button_text ?? $t('close') }}</span>
        <IconCross class="size-[0.8em]" stroke-width="4" />
      </button>
    </div>
  </AnimatedContainer>
</template>

<script setup lang="ts">
const globals = inject(GlobalsKey)

const consentCookie = useCookie('consentCookie')
const showCookieBanner = ref(!consentCookie.value)

function closeCookieBanner() {
  consentCookie.value = 'true'
  showCookieBanner.value = false
}
</script>

<style scoped>
.gradient {
  background: radial-gradient(
    circle at bottom right,
    rgba(253, 253, 252, 1) 0%,
    rgba(236, 235, 233, 1) 30%,
    rgba(207, 212, 225, 1) 100%
  );
}
</style>
